<template>
    <form @submit="enviarFormulario">
        <div class="row">
            <div class="row col-md-12">
                <div class="col-md-4 ">
                    <div class="form-group">
                        <label for="rut-completo" class="form_label ">Rut</label>
                        <input class="form-control" :class="{'is-invalid': validaciones.rut.error}" @keyup="formateaRut()" @change="buscar()" type="text" v-model="form.rut" id="rut-completo" />
                    </div>
                </div>
                <div class="col-md-4 ">
                    <div class="form-group">
                        <label for="name" class="form_label ">Razón social</label>
                        <input class="form-control" type="text" v-model="form.name" :readonly="readonly" :class="{'is-invalid': validaciones.name.error}" required="required" maxlength="255" id="name">
                    </div>
                </div>
                <div class="form-group col-md-4">
                    <label for="status">Activo</label>
                    <div class="w-100">
                        <p-check class="p-default p-bigger" color="primary-o" v-model="form.status" />
                    </div>
                </div>
            </div>
            <div class="row col-md-12 mb-3">
                <div class="col-md-12">
                    <h3 class="m-0 text-dark">Contactos del proveedor</h3>
                </div>
            </div>
            <div class="row col-md-12">
                <div class="col-md-4 ">
                    <div class="form-group">
                        <label for="provider-name" class="form_label ">Nombre</label>
                        <input class="form-control" type="text" v-model="form.provider_contacts[0].name" :readonly="readonly" maxlength="255" id="provider-name" :class="{'is-invalid': validaciones.provider_contacts.name.error}">
                    </div>
                </div>
                <div class="col-md-4 ">
                    <div class="form-group">
                        <label for="email" class="form_label ">Correo</label>
                        <input class="form-control" type="email" v-model="form.provider_contacts[0].email" :readonly="readonly" maxlength="255" id="email" :class="{'is-invalid': validaciones.provider_contacts.email.error}">
                    </div>
                </div>
                <div class="col-md-4 ">
                    <div class="form-group">
                        <label for="phone" class="form_label ">Teléfono</label>
                        <input class="form-control" type="text" v-model="form.provider_contacts[0].phone" :readonly="readonly" maxlength="20" id="phone">
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { Funciones, Validaciones } from '../../utils/funciones';

export default {
    name: 'UsuariosForm',
    props: {
        save: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        save: function (value) {
            console.log('cambio el save!!!');
            if(value) {
                this.enviarFormulario();
            }
        }
    },
    data: () => {
        return {
            form: {
                id: null,
                rut: null,
                name: null,
                status: true,
                    provider_contacts: [
                        {
                            id: null,
                            name: null,
                            email: null,
                            phone: null
                        }
                    ]
            },
            readonly: true,
            validaciones: {
                rut: {
                    error: false,
                    reglas: 'rut'
                },
                name: {
                    error: false,
                    reglas: 'not-empty'
                },
                provider_contacts: {
                    name: {
                        error: false,
                        reglas: 'not-empty'
                    },
                    email: {
                        error: false,
                        reglas: 'allow-empty|email'
                    }
                }
            }
        }
    },
    methods: {
        formateaRut() {
            let self = this;
            if(self.form.rut && self.form.rut.length > 1) {
                self.form.rut = Funciones.formateaRut(self.form.rut); 
            }
        },
        buscar() {
            let self = this;
            if(Funciones.Valida_Rut(self.form.rut)) {
                self.validaciones.rut.error = false;

                self.axios.get(`providers/find`, {
                    params: {rut: self.form.rut}
                })
                .then(function (response) {
                    if(!response.error) {
                        if(response.data == null || response.data.length == 0 ) {
                            self.readonly = false;
                            
                            self.form.id = null;
                            self.form.name = null;
                            self.form.status = true;
                            self.form.provider_contacts[0].id = null;
                            self.form.provider_contacts[0].name = null;
                            self.form.provider_contacts[0].email = null;
                            self.form.provider_contacts[0].phone = null;
                        } else {
                            self.readonly = true;

                            self.form.id = response.data.id;
                            self.form.name = response.data.name;
                            self.form.status = response.data.status;
                            self.form.provider_contacts[0].id = response.data.provider_contacts[0].id;
                            self.form.provider_contacts[0].name = response.data.provider_contacts[0].name;
                            self.form.provider_contacts[0].email = response.data.provider_contacts[0].email;
                            self.form.provider_contacts[0].phone = response.data.provider_contacts[0].phone;
                            self.$toastr.w(`El rut <b>${self.form.rut}</b> existe en el sistema`, "Advertencia");
                        }
                    } else {
                        self.$toastr.e(response.message, "Error");
                    }
                }).catch(function (error) {
                    self.$toastr.e(error, "Exception");
                });
            } else {
                self.validaciones.rut.error = true;
                self.$toastr.e("Debe ingresar un rut válido", "Error");
            }
        },
        enviarFormulario() {
            let self = this;
            Validaciones.reset(self.validaciones);
            Validaciones.validar(self.form, self.validaciones);

            let tieneError = Validaciones.hasError(self.validaciones);

            if(tieneError) {
                self.$toastr.e("El formulario tiene errores, verifique", "Error");
                self.$emit('update:save', false);
            } else {
                self.$emit('update:save', true);
                const data = {...self.form };
                data['rut_completo'] = self.form.rut;
                delete data.rut;

                self.axios.post(`users/create`, data)
                .then(function(response) {
                    self.$emit('update:save', false);
                    if(response.error) {
                        self.$toastr.e(response.message, "Error al guardar proveedor");
                    } else {
                        self.$toastr.s("Proveedor creado exitosamente", "Correcto");
                        self.$emit('entity', response.data);
                    }
                })
                .catch(function (error) {
                    self.$emit('update:save', false);
                    self.$toastr.e(error, "Exception");
                });
            }
        }
    }
}
</script>

<style>
</style>